import React, { ChangeEventHandler } from "react";
import { FC, ChangeEvent, useState, FormEventHandler } from "react";
import "./Form.scss";

export const useForm = (initialValues: any, validateInputOnChange?: (arg: any) => void) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState<any>({});

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const fieldValue = { [name]: value };

    setValues({
      ...values,
      ...fieldValue,
    });

    if (validateInputOnChange !== undefined) {
      validateInputOnChange(fieldValue);
    }
  };

  const reset = () => {
    setValues(initialValues);
    setErrors({});
  };

  return { values, setValues, errors, setErrors, handleInputChange, reset };
};

interface AppFormProps {
  name: string;
  action?: string;
  method?: string;
  children: JSX.Element | JSX.Element[];
  onSubmit: FormEventHandler<HTMLFormElement>;
}

export const Form: FC<AppFormProps> = (props: AppFormProps) => {
  const { name, action, method, children, onSubmit } = props;

  return (
    <form
      noValidate
      autoComplete="off"
      action={action}
      method={method}
      id={name}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
};
