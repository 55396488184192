import React from "react";
import { DeviceType, useScreenDimensions } from "../../../common";
import { NavDesktop, NavLink, NavLinkProps, NavMobile } from "../../../components";
import "./NavBar.scss";

function renderLinks(links: Array<NavLinkProps>) {
  return links.length > 0
    ? links.map((link) => {
        return <NavLink key={link.key} toPath={link.toPath} text={link.text} />;
      })
    : null;
}

export const NavBar: React.FC = () => {
  const screenDimensions = useScreenDimensions();
  const isMobile =
    screenDimensions?.deviceType === DeviceType.LARGE ||
    screenDimensions?.deviceType === DeviceType.MEDIUM ||
    screenDimensions?.deviceType === DeviceType.EXTRA_SMALL ||
    screenDimensions?.deviceType === DeviceType.SMALL;

  return (
    <div className="nav-bar">
      {isMobile ? <NavMobile onRenderLinks={renderLinks} /> : <NavDesktop />}
    </div>
  );
};
