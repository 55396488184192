import React, { FormEvent, useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Button, isValidEmail } from "../../../common";
import "./ContactForm.scss";
import emailjs from "@emailjs/browser";
import { Form, useForm } from "../Form";

export type ContactFormModel = {
  fullName: string;
  email: string;
  message: string;
};

const initialFormValues: ContactFormModel = {
  fullName: "",
  email: "",
  message: "",
};

interface ContactFormProps {}

export const ContactForm: React.FC<ContactFormProps> = (props: ContactFormProps) => {
  const componentName = "contact-form";
  const { t } = useTranslation();
  const [formStatusMessage, setFormStatusMessage] = useState("");

  const validate = (fieldValue = values) => {
    let errorMessages = { ...errors };
    if ("fullName" in fieldValue)
      errorMessages.fullName = fieldValue.fullName ? "" : t("contact-form_full_name_error");
    if ("email" in fieldValue)
      errorMessages.email = fieldValue.email ? "" : t("contact-form_email_error");
    if ("message" in fieldValue)
      errorMessages.message = fieldValue.message ? "" : t("contact-form_message_error");
    setErrors({ ...errorMessages });

    let formErrors = errorMessages;
    if (fieldValue === values) return Object.values(formErrors).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFormValues, validate);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate?.()) {
      if (isValidEmail(values.email)) {
        sendEmail();
      } else {
        let errorMessages = { ...errors };
        errorMessages.email = t("contact-form_enter-correct-email");
        setErrors({ ...errorMessages });
      }
    }
  };

  const sendEmail = () => {
    setFormStatusMessage(t("contact-form_status_message-sending"));
    emailjs.send("service_c3tv2e5", "template_102auf9", values, "z2IgKFjfNemUwtwRb").then(
      (result: any) => {
        setFormStatusMessage(t("contact-form_status_message-sent"));
      },
      (error: any) => {
        setFormStatusMessage(t("contact-form_status_message-error"));
      }
    );
  };

  return (
    <Form name={componentName} onSubmit={handleSubmit}>
      <div className="contact-form__row contact-form__row-full-name">
        <div className="contact-form__input">
          <label htmlFor="fullName">
            {t("full_name")}: <span>*</span>
          </label>
          <input
            id="fullName"
            name="fullName"
            value={values.fullName}
            onChange={handleInputChange}
          />
          <span className="contact-form__input-message">{errors.fullName}</span>
        </div>
        <div className="contact-form__input">
          <label htmlFor="email">
            Email: <span>*</span>
          </label>
          <input id="email" name="email" value={values.email} onChange={handleInputChange} />
          <span className="contact-form__input-message">{errors.email}</span>
        </div>
      </div>
      <div className="contact-form__row">
        <div className="contact-form__input">
          <label htmlFor="email">
            {t("message")}: <span>*</span>
          </label>
          <textarea
            rows={8}
            id="message"
            name="message"
            value={values.message}
            onChange={handleInputChange}
          />
          <span className="contact-form__input-message">{errors.message}</span>
        </div>
      </div>
      <p className="form-status-message">{formStatusMessage}</p>
      <div className="contact-form__row contact-form__action-row">
        <Button text={t("send")} type="submit" />
      </div>
    </Form>
  );
};
