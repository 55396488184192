import React from "react";
import { Link } from "gatsby";
import { ActivityState } from "../../common";
import { useAppSelector } from "../../modules/redux";
import "./NavLink.scss";

const componentName = "nav-link";
export interface NavLinkProps {
  key?: string;
  toPath: string;
  text: string;
  className?: string;
}

export const NavLink: React.FC<NavLinkProps> = (props: NavLinkProps) => {
  const { toPath, text, className } = props;
  const { currentUri } = useAppSelector((state) => state.appContext);
  const activityState = toPath === currentUri ? ActivityState.ACTIVE : ActivityState.INACTIVE;
  return (
    <Link
      to={toPath}
      className={`${componentName} ${componentName}--${activityState}${className ? className : ""}`}
    >
      {text}
    </Link>
  );
};
