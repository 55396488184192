import React from "react";
import { Link } from "gatsby";
import "./AnchorLink.scss";

interface AnchorLinkProps {
  linkTo: string;
  text: string;
}

export const AnchorLink: React.FC<AnchorLinkProps> = (props: AnchorLinkProps) => {
  const componentName = "anchor-link";
  const { linkTo, text } = props;

  return (
    <Link to={linkTo} className={componentName}>
      {text}
    </Link>
  );
};
