import React from "react";
import "./SectionTitle.scss";

interface SectionTitleProps {
  text: string;
}

export const SectionTitle: React.FC<SectionTitleProps> = (props: SectionTitleProps) => {
  const { text } = props;

  return <h1 className="section-title">{text}</h1>;
};
