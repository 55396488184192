import React from "react";
import { ContentWrapper } from "../../../common";
import "./SectionNote.scss";

interface SectionNoteProps {
  text: string;
  ImageComponent?: React.FC;
}

export const SectionNote: React.FC<SectionNoteProps> = (props: SectionNoteProps) => {
  const componentName = "section-note";
  const { ImageComponent, text } = props;

  return (
    <ContentWrapper>
      <div className={`${componentName}`}>
        {ImageComponent ? (
          <div className={`${componentName}__img-container`}>
            <ImageComponent />
          </div>
        ) : null}
        <div className={`${componentName}__text-container`}>
          <p>{text}</p>
        </div>
      </div>
    </ContentWrapper>
  );
};
