import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { MainServicesList, OtherServicesList, Section, SectionNote, SectionTitle } from "..";
import { Card, ContentWrapper, FAIcon } from "../../common";
import "./SectionServices.scss";
import { SectionInfo } from "../Section/SectionInfo/SectionInfo";

const KeyHandImg: React.FC = () => {
  return (
    <div className="key-hand-img">
      <FAIcon icon={["fas", "key"]} size="1x" className="key-hand-img__key" />
      <FAIcon icon={["fas", "hand-holding"]} size="2x" className="key-hand-img__hand" />
    </div>
  );
};

interface SectionServicesProps {}

export const SectionServices: React.FC<SectionServicesProps> = (props: SectionServicesProps) => {
  const componentName = "section-services";
  const { t } = useTranslation();

  return (
    <Section id={componentName} className={componentName}>
      <div className={`${componentName}__container`}>
        <SectionTitle text={t("services")} />
        <SectionNote ImageComponent={KeyHandImg} text={t("home_services_note_key-in-hand")} />
        <MainServicesList />
        <SectionInfo text={t("home_services_service-1_info")} />
        <SectionNote text={t("home_services_note_other-services")} />
        <OtherServicesList />
      </div>
    </Section>
  );
};
