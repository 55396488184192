import React from "react";
import { FAIcon } from "../../common";
import { useAppSelector } from "../../modules/redux";
import "./ContactList.scss";

interface ContactListProps {}

export const ContactList: React.FC<ContactListProps> = ({}) => {
  const { infoEmail, phone, mobilePhone } = useAppSelector((state) => state.appContext);

  return (
    <div className="contact-list">
      <div className="contact-list__item">
        <FAIcon className="contact-list__item__icon" icon={["fas", "phone"]} />
        <span className="contact-list__item__text">{phone}</span>
      </div>
      <div className="contact-list__item">
        <FAIcon className="contact-list__item__icon" icon={["fas", "mobile-screen-button"]} />
        <span className="contact-list__item__text">{mobilePhone}</span>
      </div>
      <div className="contact-list__item">
        <FAIcon className="contact-list__item__icon" icon={["fas", "envelope"]} />
        <span className="contact-list__item__text">{infoEmail}</span>
      </div>
    </div>
  );
};
