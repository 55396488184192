import React, { FC } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Rights } from "..";
import { FAIcon } from "../../common";
import "./Footer.scss";
import { FooterItem } from "./FooterItem/FooterItem";
import { useAppSelector } from "../../modules/redux";

const componentName = "footer";
export interface FooterProps {}

export const Footer: FC<FooterProps> = (props: FooterProps) => {
  const componentName = "footer";
  const { t } = useTranslation();
  const { companyName, OIB, infoEmail, phone, mobilePhone, address } = useAppSelector(
    (state) => state.appContext
  );

  return (
    <footer className={componentName}>
      <div className={`${componentName}__container-cover`}>
        <div className={`${componentName}__container`}>
          <div className={`${componentName}__container__content`}>
            <FooterItem
              className={`${componentName}__container__content__about-us`}
              title={t("about-us")}
            >
              <p>
                <b>{companyName}</b> {t("company_description")}
              </p>
              <p>
                <b>OIB:</b> {OIB}
              </p>
              <div className={`${componentName}__container__content__about-us__contact`}>
                <FAIcon className="contact-list__item__icon" icon={["fas", "envelope"]} />
                <span className="contact-list__item__text">{infoEmail}</span>
              </div>
              <div className={`${componentName}__container__content__about-us__contact`}>
                <FAIcon className="contact-list__item__icon" icon={["fas", "phone"]} />
                <span className="contact-list__item__text">{phone}</span>
              </div>
              <div className={`${componentName}__container__content__about-us__contact`}>
                <FAIcon
                  className="contact-list__item__icon"
                  icon={["fas", "mobile-screen-button"]}
                />
                <span className="contact-list__item__text">{mobilePhone}</span>
              </div>
              <div className={`${componentName}__container__content__about-us__contact`}>
                <FAIcon className="contact-list__item__icon" icon={["fas", "map-marker-alt"]} />
                <span className="contact-list__item__text">{address}</span>
              </div>
            </FooterItem>
            <FooterItem
              className={`${componentName}__container__content__services`}
              title={t("services")}
            >
              <p>
                {`${t("home_services_service-1-1")}, ${t("home_services_service-1-2")},
                ${t("home_services_service-1-3")}, ${t("home_services_service-1-4")},
                ${t("home_services_service-1-5")}, ${t("home_services_service-1-6")},
                ${t("home_services_service-1-7")}, ${t("home_services_service-1-8")},
                ${t("home_services_service-1-9")}, ${t("home_services_service-1-10")}.`}
              </p>
              <p>
                {`${t("home_services_service-2-1")}, ${t("home_services_service-2-2")},
                ${t("home_services_service-2-3")}, ${t("home_services_service-2-4")},
                ${t("home_services_service-2-5")}, ${t("home_services_service-2-6")},
                ${t("home_services_service-2-7")}, ${t("home_services_service-2-8")},
                ${t("home_services_service-2-9")}, ${t("home_services_service-2-10")},
                ${t("home_services_service-2-11")}, ${t("home_services_service-2-12")},
                ${t("home_services_service-2-13")}.`}
              </p>
              <div className={`${componentName}__container__content__services__list`}>
                <ul className={`${componentName}__container__content__services__list__item`}></ul>
                <ul className={`${componentName}__container__content__services__list__item`}></ul>
              </div>
            </FooterItem>
          </div>
          <div className={`${componentName}__content__contacts__rights-section`}>
            <Rights />
          </div>
        </div>
      </div>
    </footer>
  );
};
