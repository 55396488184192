export function toggleLayoutOverflowHidden(overflowHide: boolean) {
  const overflowHiddenClassName = "layout-overflow-hidden";
  overflowHide
    ? document.body.classList.add(overflowHiddenClassName)
    : document.body.classList.remove(overflowHiddenClassName);
}

export function isValidEmail(email: string) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}
