import React from "react";
import { Link } from "gatsby";

import logo from "../../assets/images/balaena-logo.png";
import "./Brand.scss";

interface BrandProps {}

const Brand: React.FC<BrandProps> = () => {
  const componentName = "brand";

  return (
    <div className={componentName}>
      <Link className={`${componentName}__container`} to="/" />
    </div>
  );
};

export default Brand;
