import React from "react";

import "./ContentWrapper.scss";

interface ContentWrapperProps {
  children: JSX.Element | Array<JSX.Element>;
}

export const ContentWrapper: React.FC<ContentWrapperProps> = (props: ContentWrapperProps) => {
  const { children } = props;

  return <div className="content-wrapper">{children}</div>;
};
