import React from "react";
import "./Button.scss";

interface ButtonProps {
  text: string;
  type?: "button" | "submit" | "reset" | undefined;
}

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { text, type } = props;

  return (
    <button className="button" type={type}>
      {text}
    </button>
  );
};
