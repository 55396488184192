import React from "react";
import { Card } from "../../../common";
import { useTranslation } from "gatsby-plugin-react-i18next";
import "./MainServicesList.scss";

interface MainServicesListProps {}

export const MainServicesList: React.FC<MainServicesListProps> = ({}) => {
  const componentName = "main-services-list";
  const { t } = useTranslation();

  return (
    <div className={`${componentName}`}>
      <Card text={t("home_services_service-1-1")} />
      <Card text={t("home_services_service-1-2")} />
      <Card text={t("home_services_service-1-3")} />
      <Card text={t("home_services_service-1-4")} />
      <Card text={t("home_services_service-1-5")} />
      <Card text={t("home_services_service-1-6")} />
      <Card text={t("home_services_service-1-7")} />
      <Card text={t("home_services_service-1-8")} />
      <Card text={t("home_services_service-1-9")} />
      <Card text={t("home_services_service-1-10")} />
    </div>
  );
};
