import React from "react";
import "./Card.scss";

interface CardProps {
  text: string;
}

export const Card: React.FC<CardProps> = (props: CardProps) => {
  const componentName = "card";
  const { text } = props;
  return (
    <div className={`${componentName}__container`}>
      <span className={`${componentName}__container__text`}>{text}</span>
    </div>
  );
};
