import React from "react";
import { ContactList, LangNav } from "../..";
import { Brand } from "../../../common";
import "./NavDesktop.scss";

interface NavDesktopProps {}

export const NavDesktop: React.FC<NavDesktopProps> = (props: NavDesktopProps) => {
  const componentName = "nav-desktop";

  return (
    <div className={componentName}>
      <Brand />
      <ContactList />
      <LangNav />
    </div>
  );
};
