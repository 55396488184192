import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { Card } from "../../../common";
import "./OtherServicesList.scss";

interface OtherServicesListProps {}

export const OtherServicesList: React.FC<OtherServicesListProps> = ({}) => {
  const componentName = "other-services-list";
  const { t } = useTranslation();

  return (
    <div className={componentName}>
      <Card text={t("home_services_service-2-1")} />
      <Card text={t("home_services_service-2-2")} />
      <Card text={t("home_services_service-2-3")} />
      <Card text={t("home_services_service-2-4")} />
      <Card text={t("home_services_service-2-5")} />
      <Card text={t("home_services_service-2-6")} />
      <Card text={t("home_services_service-2-7")} />
      <Card text={t("home_services_service-2-8")} />
      <Card text={t("home_services_service-2-9")} />
      <Card text={t("home_services_service-2-10")} />
      <Card text={t("home_services_service-2-11")} />
      <Card text={t("home_services_service-2-12")} />
      <Card text={t("home_services_service-2-13")} />
    </div>
  );
};
