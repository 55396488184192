import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Section, SectionTitle } from "..";
import { ContentWrapper } from "../../common";
import "./SectionReferences.scss";

interface SectionReferencesProps {}

export const SectionReferences: React.FC<SectionReferencesProps> = (
  props: SectionReferencesProps
) => {
  const componentName = "section-references";
  const { t } = useTranslation();

  return (
    <Section id={componentName} className={componentName}>
      <div className={`${componentName}__container`}>
        <ContentWrapper>
          <SectionTitle text={t("references")} />
          <p>{t("home_references_paragraph")}</p>
        </ContentWrapper>
      </div>
    </Section>
  );
};
