import React from "react";
import "./ContentTitle.scss";

interface ContentTitleProps {
  text: string;
}

export const ContentTitle: React.FC<ContentTitleProps> = (props: ContentTitleProps) => {
  const componentName = "content-title";
  const { text } = props;

  return <h3 className={componentName}>{text}</h3>;
};
