import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import {
  faGlobe,
  faEnvelope,
  faPhone,
  faMobileScreenButton,
} from "@fortawesome/free-solid-svg-icons";

library.add(fas, faGlobe, faEnvelope, faPhone, faMobileScreenButton, faCopyright);

/** @param {FontAwesomeIconProps} props */
/* eslint-disable react/jsx-props-no-spreading */
const FAIcon: React.FC<FontAwesomeIconProps> = ({ ...props }: FontAwesomeIconProps) => (
  <FontAwesomeIcon {...props} />
);

export { FAIcon };
