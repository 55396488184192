import React, { useState } from "react";
import Hamburger from "hamburger-react";
import { NAVBAR_CONFIG } from "../NavBar/NavBarConfig";
import { ContactList, LangNav, NavLinkProps } from "../..";
import { Brand, toggleLayoutOverflowHidden } from "../../../common";
import "./NavMobile.scss";

interface NavMobileProps {
  onRenderLinks: (links: Array<NavLinkProps>) => JSX.Element[] | null;
}

export const NavMobile: React.FC<NavMobileProps> = (props: NavMobileProps) => {
  const componentName = "nav-mobile";

  return (
    <div className={componentName}>
      <div className={`${componentName}__row ${componentName}__row-brand-lang`}>
        <Brand />
        <LangNav />
      </div>
      <div className={`${componentName}__row ${componentName}__row-contacts`}>
        <ContactList />
      </div>
    </div>
  );
};
