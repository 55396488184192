import React from "react";
import { Section, SectionTitle } from "..";
import { AnchorLink, ContentWrapper } from "../../common";
import { useTranslation } from "gatsby-plugin-react-i18next";
import "./SectionAboutUs.scss";

interface SectionAboutUsProps {}

export const SectionAboutUs: React.FC<SectionAboutUsProps> = (props: SectionAboutUsProps) => {
  const componentName = "section-about-us";
  const { t } = useTranslation();

  return (
    <Section id={componentName} className={componentName}>
      <div className={`${componentName}__container`}>
        <ContentWrapper>
          <SectionTitle text={t("about-us")} />
          <p>{t("home_about-us_paragraph-one")}</p>
          <p>{t("home_about-us_paragraph-two")}</p>
          <div className={`${componentName}__container__action`}>
            <AnchorLink linkTo="#section-contact" text={t("action_contact")} />
          </div>
        </ContentWrapper>
      </div>
    </Section>
  );
};
