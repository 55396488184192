import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { FAIcon } from "../../../common";

import "./Rights.scss";
import { useAppSelector } from "../../../modules/redux";

export const Rights: React.FC = () => {
  const componentName = "rights";
  const { t } = useTranslation();
  const { companyName } = useAppSelector((state) => state.appContext);
  const year = new Date().getFullYear();
  const rightsText = t("rights");

  const rightsString = ` ${year}. ${companyName} ${rightsText}`;

  return (
    <div className={componentName}>
      <FAIcon icon={["far", "copyright"]} />
      {rightsString}
    </div>
  );
};
