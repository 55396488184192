import React from "react";
import { ContentWrapper, FAIcon } from "../../../common";
import "./SectionInfo.scss";

interface SectionInfoProps {
  text: string;
}

export const SectionInfo: React.FC<SectionInfoProps> = (props: SectionInfoProps) => {
  const componentName = "section-info";
  const { text } = props;

  return (
    <ContentWrapper>
      <div className={`${componentName}`}>
        <div className={`${componentName}__img-container`}>
          <FAIcon icon={["fas", "info-circle"]} size="2x" className="key-hand-img__hand" />
        </div>
        <div className={`${componentName}__note__text-container`}>
          <p>{text}</p>
        </div>
      </div>
    </ContentWrapper>
  );
};
