import React from "react";
import { ContentTitle } from "../../../common";

import "./FooterItem.scss";

interface FooterItemProps {
  title: string;
  className?: string;
  children: JSX.Element | Array<JSX.Element>;
}

export const FooterItem: React.FC<FooterItemProps> = (props: FooterItemProps) => {
  const { title, className, children } = props;

  return (
    <div className={`footer-item ${className ? className : ""}`}>
      <div className="footer-item__title">
        <ContentTitle text={title} />
      </div>
      <div className="footer-item__content">{children}</div>
    </div>
  );
};
