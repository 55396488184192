import React, { FC } from "react";
import { NavBar } from "..";
import "./Header.scss";

export interface HeaderProps {}

export const Header: FC<HeaderProps> = (props: HeaderProps) => {
  const componentName = "header";

  return (
    <header className={componentName}>
      <NavBar />
    </header>
  );
};
