import React from "react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { FAIcon } from "../../../common";
import "./LangNav.scss";

export const LangNav: React.FC = () => {
  const componentName = "lang-nav";
  const { languages, originalPath, language } = useI18next();

  return (
    <div className={componentName}>
      <div className={`${componentName}__icon`}>
        <FAIcon icon={["fas", "globe"]} size="lg" />
      </div>
      <nav className={`${componentName}__nav`}>
        {languages.map((lng) => (
          <Link
            key={lng}
            to={originalPath}
            language={lng}
            className={`${componentName}__nav__link ${componentName}__nav__link__${
              lng === language ? "active" : "inactive"
            }`}
          >
            {lng}
          </Link>
        ))}
      </nav>
    </div>
  );
};
