import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AppContextState {
  currentUri: string | undefined;
  companyName: string;
  infoEmail: string;
  phone: string;
  mobilePhone: string;
  address: string;
  OIB: string;
}

const initialState: AppContextState = {
  currentUri: undefined,
  companyName: "Balaena d.o.o.",
  infoEmail: "info@balaena.hr",
  phone: "+385/51 678051",
  mobilePhone: "+385/99 6678050",
  address: "Jurja Dobrile 1 HR- 51000 Rijeka",
  OIB: "20120887218",
};

const appContextSlice = createSlice({
  name: "appContext",
  initialState,
  reducers: {
    setCurrentUri(state, action: PayloadAction<string>) {
      state.currentUri = action.payload;
    },
  },
});

export const { setCurrentUri } = appContextSlice.actions;

export default appContextSlice.reducer;
