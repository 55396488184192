import React from "react";
import { useTranslation } from "react-i18next";
import { ContactForm, Section, SectionTitle, WaveBackground } from "..";
import { ContentTitle, FAIcon } from "../../common";
import { useAppSelector } from "../../modules/redux";
import "./SectionContact.scss";

interface SectionContactProps {}

export const SectionContact: React.FC<SectionContactProps> = (props: SectionContactProps) => {
  const componentName = "section-contact";
  const { t } = useTranslation();
  const { companyName, infoEmail, phone, mobilePhone, address } = useAppSelector(
    (state) => state.appContext
  );

  return (
    <Section id={componentName} className={componentName}>
      <div className={`${componentName}__container`}>
        <SectionTitle text={t("contact")} />
        <div className="contact__container">
          <div className="contact__container__item contact__container__item-info">
            <div className="contact__container__item__title-container">
              <ContentTitle text={`${companyName} ${t("contact-info")}`} />
            </div>
            <div className="contact__container__item__container">
              <div className="contact__container__item__container__content">
                <FAIcon className="contact-list__item__icon" icon={["fas", "envelope"]} />
                <span className="contact-list__item__text">{infoEmail}</span>
              </div>
              <div className="contact__container__item__container__content">
                <FAIcon className="contact-list__item__icon" icon={["fas", "phone"]} />
                <span className="contact-list__item__text">{phone}</span>
              </div>
              <div className="contact__container__item__container__content">
                <FAIcon
                  className="contact-list__item__icon"
                  icon={["fas", "mobile-screen-button"]}
                />
                <span className="contact-list__item__text">{mobilePhone}</span>
              </div>
              <div className="contact__container__item__container__content">
                <FAIcon className="contact-list__item__icon" icon={["fas", "map-marker-alt"]} />
                <span className="contact-list__item__text">{address}</span>
              </div>
            </div>
          </div>
          <div className="contact__container__item contact__container__item-form">
            <div className="contact__container__item__title-container">
              <ContentTitle text={t("send_message")} />
            </div>
            <div className="contact__container__item__content">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
